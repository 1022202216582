.footerContainer {
    position: fixed;
    bottom: 0;
    text-align: center;
    max-width: 500px;
    padding: 16px 24px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
}

.borderSubmitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 30px;
    height: 44px;
}

