.bookscreen-comment-seemore {
    height: 100%;
    width: max-content;
    display: flex;
    align-items: center;
    padding-right: 16px;
}

.bookScreen-comment-seemore-container {
    border: 1px solid #595959;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookscreen-comment-seemore-logo {
    height: 24px;
    width: 24px;
}

.bookScreen-comment-seemore-police {
    @include font-sub-title($font-size: 20px, $font-weight: 500);
    text-transform: uppercase;
    font-feature-settings: "lnum" on;
    padding-left: 6.5px;
}

.bookscreen-comment-listcomment-container {
    padding-right: 16px;
    margin-bottom: 32px;
    margin-top: 16px;
    @include for-phone-only{
        padding-right: 16px;
        margin-bottom: 25px;
    }
}

.bookscreen-comment-card-container {
    width: auto;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0px 16px;
    padding-top: 16px;
    padding-bottom: 13px;
    @include for-phone-only{
        padding: 0px 16px;
        padding-top: 16px;
        padding-bottom: 10px;
    }
}

.bookscreen-comment-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bookscreen-comment-picture-style {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    @include for-phone-only{
        height: 40px;
        width: 40px;
    }
}

.bookscreen-comment-info-container {
    padding-left: 16px;
}

.bookscreen-comment-author-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.bookscreen-comment-author-police {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 161%;
    color: #252525;
}

.bookscreen-comment-date-police {
    font-family: Fira Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 161%;
    color: #b2b2b2;
    padding-left: 10px;
    @include for-phone-only{
        font-size: 14px;
    }
}

.bookscreen-comment-content-container {
    display: flex;
    flex-direction: column;
    width: 318px;
    padding-top: 8px;
    @include for-phone-only{
        max-width: 213px;
    }
}

.bookscreen-comment-content-title-police {
    font-family: Vollkorn;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 161%;
    color: #000000;
    @include for-phone-only{
        font-size: 18px;
        line-height: 132%;
    }
}

.bookscreen-comment-footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.bookscreen-comment-footer-police {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #595959;
    @include for-phone-only{
        font-size: 14px;
        line-height: 17px;
    }
}

.bookscreen-comment-footer-like {
    @include for-phone-only{
        width: 21px;
        height: 18px;
    }
}

.bookscreen-comment-footer-space {
    padding-left: 14px;
    padding-right: 20px;
    padding-top: 5px;
    @include for-phone-only{
        padding-left: 7px;
        padding-right: 13px;
    }
}

.bookscreen-comment-footer-comment {
    @include for-phone-only{
        width: 18.5px;
        height: 17.5px;
    }
}

.bookpeople-comment-tag-container {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 3px;
    @include for-phone-only{
        height: 20px;
        width: 20px;
    }
}

.bookpeople-comment-tags-style {
    height: 12px;
    @include for-phone-only{
        height: 9px;
    }
}

.bookpeople-comment-tags-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}