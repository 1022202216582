@mixin for-micro-phone-only {
    @media (max-width: 329px) { @content; }
}

@mixin for-phone-only {
    @media (max-width: 720px) { @content; }
}

@mixin for-tablet-only {
    @media (max-width: 900px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 1500px) { @content; }
}

@mixin font-title(
    $font-family: 'Vollkorn', 
    $font-style: normal, 
    $font-weight: 400, 
    $font-size: 21px, 
    $font-size-phone : $font-size - 2px,
    $line-height: 150%, 
    $color: #252525, 
    $text-align : center
) {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-size:  $font-size;
    line-height: $line-height;
    color: $color;
    text-align: $text-align;
    @include for-phone-only{
        font-size :  $font-size-phone;
    }
}

@mixin font-sub-title(
    $font-family: 'Fira Sans', 
    $font-style: normal, 
    $font-weight: 400, 
    $font-size: 18px, 
    $font-size-phone : $font-size - 2px,
    $line-height: 140%, 
    $color: #595959, 
    $text-align : center
) {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-size:  $font-size;
    line-height: $line-height;
    color: $color;
    text-align: $text-align;
    @include for-phone-only{
        font-size :  $font-size-phone;
    }
}