.containerPoliceConcours{
    margin: 8px 24px 24px 24px;
}

.circleConcoursContainer{
    background: #89DA8E;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 24px;
}

.ticConcoursRegistered{
    width: 30px;
}

.logoConcoursScreen{
    text-align: center;
    margin-bottom: 16px;
}

.logoConcoursImg{
    width: 320px;
}

.containerPlaceholderConcours{
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}
