.iconShareReco {
    margin-right: 10px;
}

.submitBtnText {
    @include font-sub-title($color: #ffffff);
    text-transform: uppercase;
}

.buttonCompText {
    @include font-sub-title($color: #ffffff);
    text-transform: uppercase;
    letter-spacing: 0.48px;
}