.containerFooterB2B {
    margin-top: 16px;
}

.cataloguePolice {
    height: 26px;
    @include font-sub-title($font-weight: 500, $line-height: 160%, $color: #252525);
    letter-spacing: 0.01em;
    font-feature-settings: 'lnum' on;
    
}

.containerImageFooterB2B {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.B2BCatalogLogoSize {
    width: 80px;
    height: auto;
    @include for-phone-only{
        width: 50px;
    }
}
  