.containerTitleSubtitleHome {
    padding : 24px 24px;
    background-size: cover
}
  
.title {
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 20px rgba(0, 0, 0, 0.1);
    @include font-title($font-size: 24px);
}

.titleHomeWhite{
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 20px rgba(0, 0, 0, 0.1);
    @include font-title($color : #FFFFFF);
}

.subtitleHome {
    @include font-sub-title($text-align:left);
    margin-top: 16px;
    margin-left: 10px;
  }

  .subtitleHomeWhite {
    @include font-sub-title($color : #FFFFFF);
    margin-top: 16px;
  }

.homeBGBarillet{
    position: absolute;
    margin-top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    width: 215px;
}

.homeBGButtoir{
    position: absolute;
    margin-top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    width: 48px;

}

.containerHomeIconBG{
    position: absolute;
    z-index: 0;
    overflow: hidden;
    width: 250px;
    height: 100%;
}

.containerSubtitleHome{
    display: flex;
    align-items: center;
    justify-content: center;
    margin : 0px 12px
}