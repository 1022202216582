.containerPopularity {
    padding: 16px;
    display: flex;
    flex-direction: column;
    @include for-phone-only{
        padding: 15px 6vw;
    }
}

.popularityPolice {
    @include font-sub-title($font-weight: bold, $font-size: 20px, $font-size-phone: 14px, $color: #252525);
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @include for-phone-only{
        line-height: 16.8px;
    }
}

.containerAllTags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include for-phone-only{
        justify-content: space-evenly;
    }
}

.positionPopularity {
    text-align: center;
    padding-bottom: 32.6px;
    @include for-phone-only{
        padding-bottom: 15px;
    }
}