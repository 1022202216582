.bookscreen-comment-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px;
    padding-top: 16px;
    @include for-phone-only{
        padding: 0px 16px;
        padding-top: 13px;
    }
}

.bookscreen-comment-icon-container {
    padding-right: 8px;
}

 
.bookscreen-comment-icon-style {
    @include for-phone-only{
        height: 24px;
        width: 24px;
    }
}
  