.mentionsLegalesContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
}

.politiqueContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    @include for-phone-only{
        padding-bottom: 70px;
    }
}
  
.mentionLegalesPolice {
    @include font-sub-title($font-size: 16px, $line-height: 160%);
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    cursor: pointer;
}
