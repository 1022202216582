.containerListResultWoMargin {
    margin-top: 16px;
}

.midTextReco {
    @include font-title($font-size: 22px);
}

.logoJaimeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.imageJaime {
    margin-right: 8px;
}

.containerWorkHorizontalAndText {
    margin-top: 14px;
}
  

.containerScrollVertical {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
} 
.recoAuthorButtonContainer{
    display: flex;
    justify-content: center;
    margin : auto;
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: 8px;
}

.recoAuthorButtonBorder{
    display: flex;
    height: 24px;
    padding: 3px 16px;
    justify-content: center;
    align-items: center;
    gap: 5px; 
    border-radius: 90px;
    border: 1px solid var(--generalgrismoyen, #595959);
}

.recoAuthorButtonText{
    @include font-sub-title($font-size: 14px, $color:#595959, $line-height : normal);
    letter-spacing: 0.36px;
    text-transform: uppercase; 
}

.recoAuthorTextNoBook{
    @include font-sub-title( $line-height : 160%, $font-style:italic);
}

.recoAuthorContainerNoBook{
    margin: 16px;
    display: flex;
    justify-content: center;
}