.resultRecoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 16px;
}

.TopTextContainer {
    padding-top: 11px;
}

.TopTextReco {
    @include font-title($font-size: 22px);
    align-items: center;
    justify-content: center;
}

.SubTopTextContainer {
    padding-top: 16px;
    padding-bottom: 24px;
    text-align: center;
}  

.footerTextPolice {
    @include font-sub-title($font-size: 18px, $line-height: 160%);
}

.paddingBottomNoReco {
    margin: auto;
    padding-bottom: 33px;
    max-width : 500px;
    width : 100%
}
