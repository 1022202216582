.coupdecoeurContainer {
    margin: 10px 0px 20px 0px;
  }

.coupdecoeurTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 13px;
}
.HeartLogo {
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeartLogoSize {
    width: 12px;
}

.coupDeCoeurPolice {
    @include font-title($text-align:start);
    margin-left: 8px;
}


//HeartBookList
.HeartBookContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
  
//Book
.defaultCover {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b2b2b2;
}

.itemImgBookDetail {
    width: 170px;
    height: 243px;
    @include for-phone-only{
        width: 84px;
        height: 120px;
    }
    flex-shrink: 0;
}


.itemImg {
    height: 100px;
    width: 70px;
    flex-shrink: 0;
}

.coupdecoeurContainerLegend{
    margin-top: 16px;
}

.coupdecoeurIconJaimeContainer{
    height: 24px;
}