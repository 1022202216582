.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    height: 56px;
    width: 100%;
    top: 0;
    z-index: 5;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.headerWithBack {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    top: 0;
    z-index: 5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.headerLogoB2B{
    height: 40px;
}

.headerLogoB2BWithBack{
    height: 32px;
}

.headerLogoGleeph{
    height: 30px;
}

.headerTextCity{
    @include font-sub-title($font-size: 18px, $line-height: 160%, $color:#FFFFFF);
}

.headerTextMid{
    @include font-sub-title($font-size: 16px, $line-height: 160%, $color:#FFFFFF);
    letter-spacing: 0.28px;
    margin : 0px 6px

}

.backHeader {
    height: 19px;
    margin-right: 850px;
    position: absolute;
    cursor: pointer;    
    @include for-tablet-only{
        left: 10px;
    }
}

.iconBackHeader{
    height: 24px;
}
