.footerGleephContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 32px 0px;
    justify-content: space-between;
    @include for-phone-only{
        padding: 16px 0px;
        flex-direction: column;
    }
}

.footerTitleLogoContainer{
    display: flex;
    align-items: center;
    @include for-phone-only{
        flex-direction: column;
        align-items: center;
    }
}

.shareButtonContainer{
    max-width: 500px;
    margin: auto;
}


.footerGleephPolice {
    @include font-sub-title($font-size: 16px, $line-height: 160%);
    display: flex;
    margin-right: 11px;
    @include for-phone-only{
        margin-right: 0px;
    }
}

.footerLogo {
    width: 107px;
    @include for-phone-only{
        margin-top: 6px;
    }
}

.storesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    justify-content: space-evenly;
    @include for-phone-only{
        width: 80%;
        justify-content: space-evenly;
        margin-top: 12px;
    }
}
  
.footerStoreImg {
    width: 136px;
    @include for-phone-only{
        width: 110px;
    }
}