.containerBlur {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 900px;
    height: 307px;
    background: rgba(37, 37, 37, 0.5);
    @include for-tablet-only{
        width: 100%;
    }
    @include for-phone-only{
        height: 152px;
    }
}

.blurCover {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(72px);
  }

.bookDetailFixBack{
    position: absolute;
    margin-left: 24px;
    z-index: 2;
}

.backgroundOpacityListBookInShelf {
    position: absolute;
    width: 900px;
    height: 16px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.1;
    @include for-tablet-only{
        max-width: 100%;
    }
    @include for-phone-only{
        width: 100%;
        opacity: 0.1;
    }
}


.separateLine {
    border-bottom: 1px solid #e3e3e3;
    width: auto;
    margin-left: 32px;
    margin-right: 32px;
    align-self: center;
    @include for-phone-only{
        margin-left: 16px;
        margin-right: 16px;
    }
}



.bookDetailMarginGleephFooter {
    margin-left: 32px;
    @include for-phone-only{
        margin-left: 0px;
    }
}