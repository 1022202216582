.bookcover-default-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdf9e3;
}

.bookcover-default-border {
    width: 85%;
    height: 90%;
    border: 1px solid;
    border-color: #595959;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookcover-default-title-police {
    @include font-title($font-style: italic, $font-weight: bold, $font-size: 14px, $color: #595959);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}