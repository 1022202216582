.reglementConcoursContainer {
    display: flex;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 8px;
}

.reglementPolice{
    @include font-sub-title($font-size: 14px, $font-style:italic);
}
  