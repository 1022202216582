// AutocompleteTextInput

.autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 24px;
}

input {
    text-align: center;
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: 16px;
    margin: auto;
}

input:focus {
    outline: none;
}
  


#myBookInput {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    padding-right: 45px;
    padding-left: 16px;
    margin: auto;
    text-align: left;
    box-shadow: 1px -20px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

#myBookInputShadow {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    padding-right: 45px;
    padding-left: 16px;
    margin: auto;
    text-align: left;
    border-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 40px rgba(0, 0, 0, 0.1);
}
  
.inputPolice {
    @include font-sub-title($color: #252525, $line-height: 120%);
    // font-family: Fira Sans;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 16px;
    // line-height: 120%;
    // color: #252525;
}

.autocomplete_svg {
    position: absolute;
    right: 13px;
    top: 10px;
    width: 20px;
    height: 20px;
}
  
.autocomplete-items {
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 10%),
      0px 4px 10px 0px rgb(0 0 0 / 8%);
    padding: 0 1rem;
    background-color: #fff;
    height: 350px;
    overflow: auto;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top: 1px solid #b2b2b2;
    z-index: 2;
    position: absolute;
    div {
        padding: 12px 0px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }
    div:hover {
        background-color: #e9e9e9;
    }
}

input::placeholder {
    color: #b2b2b2;
    font-style: italic;
    opacity: 1;
  }
  
// AutocompletionLINE
.containerAutoCompletion {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.itemAutoCompletionImg {
    margin: 2px 13px 2px 0px;
}

// AutocompleteText
.boldAutoCompletion {
    font-weight: 700;
}

.author {
    color: #595959;
}

.AutoCompletionLimit {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}