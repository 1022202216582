.TopTextRecoBook {
    @include font-title();
    margin: auto;
    width: 80%;
}


.itemTopTextReco {
    margin: 0px 5px 0px 5px;
    position: relative;
    top: 5px;
}

.NewSearchContainer {
    margin-bottom: 16px;
    margin-top: 16px;
}
  