.containerTag {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    @include for-tablet-only{
        padding: 0px 3.5vw;
    }
    @include for-phone-only{
        padding: 0px 0px;
    }
}

.circleTag {
    border: 1.6px solid;
    box-sizing: border-box;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only{
        border: 1.03px solid;
        height: 40px;
        width: 40px;
    }
}

.nbTagPolice {
    @include font-sub-title($font-size: 14.4px, $font-size-phone: 10px, $line-height: 17px, $color: #ffffff);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 1px 8px;
    @include for-phone-only{
        line-height: 12px;
    }
}

.containerTextTag {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    padding-top: 16px;
    @include for-phone-only{
        min-width: 54px;
        padding-top: 12px;        
    }
}

.textTagsPolice {
    @include font-sub-title($font-size: 20px, $font-size-phone: 12px, $line-height: 24px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    @include for-phone-only{
        line-height: 14.4px;
    }
}


.nbTag {
    border: 1.6px solid #f7f7f7;
    width: auto;
    height: 25.6px;
    min-width: 40px;
    border-radius: 16px;
    position: absolute;
    margin-left: 60px;
    top: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only{
        border: 1.03px solid #f7f7f7;
        height: 16px;
        min-width: 26px;
        margin-left: 20px;
        top: 305px;
    }
    @include for-micro-phone-only{
        top: 320px;
    }
}