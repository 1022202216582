.waitingConcoursBG{
    min-height: 100vh;
    height: auto;
    background: linear-gradient(180deg, #252525 0%, #252525 100%);
}

.waitingConcoursContainer{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.waitingConcoursTitle{
    @include font-title(
        $font-size: 32px, 
        $color: #FFFFFF)
}

.waitingConcoursSubTitle{
    @include font-sub-title(
        $font-size: 17px,
        $line-height: 160%,
        $color: #B2B2B2)
}

.ConcoursAnimationsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ConcoursAnimationButoir{
    position: absolute;
}

.ConcoursAnimationBarillet{
    margin-top: 35px;
}
