
.backContainerWoMargin {
    margin-top: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: inline-flex
}


.backIcon {
    margin-right: 8px;
}

.backText {
    @include font-sub-title($font-weight: 600, $line-height: 160%, $font-size: 17px);
}