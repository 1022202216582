.containerConcours{
    position: fixed;
    max-width: 502px;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
    margin-bottom: 16px;
    text-align: center;
    padding: 16px 24px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 20;
    @media (max-width: 548px) {
        margin: 0px 24px;
        margin-bottom: 16px;
    }

}

.titleConcours{
    @include font-title($font-size: 20px);
}

.titleConcours2{
    @include font-title($font-size: 18px);
}

.subTitleConcours{
    @include font-sub-title(
        $font-size: 17px, 
        $line-height: 160%,
    );
}

.containerClose{
    position: absolute;
    right : 0;
    top : 0;
    padding : 10px;
    cursor : pointer
}

.borderConcourBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 30px;
    height: 32px;
    background: #B2B2B2;
    opacity: 0.5;
}

.containerTextInputConcours{
    margin-bottom: 17px;
}

.concoursPoliceRules{
    @include font-sub-title($font-size:14px)
}

.concoursRulesContainer{
    margin-bottom: 16px;
}

.policeRulesConcours{
    @include font-sub-title($font-size: 14px, $text-align:start, $font-style:italic);
    margin : 12px 0px;
}