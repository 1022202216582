.containerPoliceLegal {
    margin-bottom: 24px;
    margin-top: 24px;
    @include for-phone-only{
        margin-bottom: 16px;
        margin-top: 16px;
    }
}

.policeLegalShare {
    display: block;
    @include font-sub-title($font-size:14px);
    // font-family: 'Fira Sans';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 12px;
    // line-height: 140%;
    // text-align: center;
    // color: #595959;
}

.textUnderline {
    text-decoration-line: underline;
}