.searchRepeteContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 327px;
    height: 32px;
    border: 1px solid #252525;
    border-radius: 90px;
    width: 100%;
    max-width: 500px;
    margin: auto;
    cursor: pointer;
}

.backRecoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 90px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 7px;
}

.searchRepeteIcon {
    margin-right: 8px;
}

.searchRepetePolice {
    @include font-sub-title($font-size: 18px, $line-height: 19px, $color: #252525);
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}