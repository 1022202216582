.placeholder_div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

}

.placeholder {
    max-width: 500px;
    width: 100%;
    margin-bottom: 23px;
}

.placeholder_left {
    position: absolute;
    width: auto;
    height: 130px;
    left : 0;
    bottom  : 0;
}
  
.placeholder_right {
    position: absolute;
    width: auto;
    height: 150px;
    right : 0;
    bottom  : 0

}

.placehorlderContainerText{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.placehorlderTextTitleStyle {
    @include font-title($line-height : 180%);
}

.placehorlderTextLotRedStyle {
    width: 40%;
    @include font-sub-title($color:#CB0538, $font-weight : 700);
}

.placehorlderTextDateStyle {
    z-index: 1;
    width: 30%;
    margin : 8px 0px;
    @include font-sub-title($font-size : 16px, $color : #252525);
}

.placeholderButtonTextStyle{
    @include font-sub-title($color : white);
}

.placeholderButtonStyle{
    z-index: 1;
    background-color: #CB0538 ;
    border-radius: 16px;
    padding : 3px 24px;
    margin-bottom: 17px;
    cursor: pointer;

}

