
.OverlayStyle {
    z-index: 1100;
    background-color: #0000004d;
    animation: 0.3s linear;
    width: 100%;
    height: 100%;
    padding: 0px 30px;
    position: fixed;
    top : 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.CardStyle {
    height: auto;
    border-radius: 24px;
    width: auto;
    animation: 0.3s linear;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 24px 32px   24px  32px;
    position: relative;
    display: flex;
    flex-direction: column;
}