
.ContainerBook {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.booklist-container {
    margin-bottom: 16px; 
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.containerBookInfo {
    display: flex;
    flex-direction: row;
}
  
.circleContainerStockandText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.circleStockContainerBookList {
    background: #89DA8E;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.ticStockBookList {
    width: 10px;
    height: 10px;
}

.textStockB2BBookList {
    @include font-sub-title($font-weight: 700, $font-size: 14px);
    text-transform: uppercase;
    font-feature-settings: 'lnum' on;
    color: #595959;
}
  
  