.containerResume {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    @include for-phone-only{
            padding: 0px 16px;
            padding-top: 15px;        
    }
}

.resumeTitlePolice {
    @include font-sub-title($font-weight: bold, $color: #252525, $line-height: 24px, $font-size: 20px, $font-size-phone: 14px);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    @include for-phone-only{
        line-height: 17px;
    }
}