.legalNotice,
.legalNoticeSubtitle {
  @include font-sub-title($font-weight:bold, $color: #252525);
}

.legalNotice {
    padding-top: 25px;
    font-size: 22px;
    text-align: center;
  }
  
.legalNoticeText {
margin-top: 0;
}
  
.legalNoticeTextMiddle {
margin-top: 0;
text-align: center;
}

.legalNoticeLittleInfo {
  margin-top: 0;
  font-size: 10px;
  }
    