.modalConcoursContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
}


.modalConcoursTextLotRedStyle {
    @include font-sub-title($color:#CB0538, $font-weight : 500);
}

.modalConcoursTextTitleStyle {
    @include font-title();
}

.modalConcoursButtonFullStyle{
    z-index: 1;
    background-color: #CB0538 ;
    border-radius: 50px;
    padding : 13px 0px;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0px 4px 8px 0px rgba(245, 130, 41, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalConcoursButtonBorderedStyle{
    z-index: 1;
    border: 1px solid #CB0538;
    border-radius: 50px;
    padding : 13px 0px;
    width: 100%;
    box-shadow: 0px 4px 8px 0px rgba(245, 130, 41, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}


.modalConcoursButtonFullTextStyle{
    @include font-sub-title($color : white, $font-weight:500);
}

.modalConcoursButtonBorderedTextStyle{
    @include font-sub-title($color : #CB0538, $font-weight:500);
}

.pictureContainer{
    width: 125px;
}


.policeColorRelay{
    color: var(--Rouge-Relay-Vote, #CB0538);
}