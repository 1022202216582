.resumePolice {
    @include font-sub-title($color: #252525, $line-height: 160%, $font-size-phone:14px, $text-align:left);
}

.ReadMorePolice {
    @include font-sub-title($color: #b2b2b2,$font-weight: 600, $line-height: 25px, $font-size-phone:14px, $text-align:left);
    @include for-phone-only{
        line-height: 19.32px;
    }
}
  