.TopWaitTextReco {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.forceWidth {
    width: 75%;
    margin: auto;
}
  
.loading-animation{
    margin-top: 56px;
    width: 80px;
    @include for-phone-only{
        margin-top: 32px;
    }
}