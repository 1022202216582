.bookScreen-authorStock-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
  
.containerAuthorPanel {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    width: auto;
    @include for-phone-only{
        padding: 12px 16px;
    }
}

.bookScreen-authorLog-container {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3;
    border-radius: 8px;
    margin-right: 16px;
    @include for-phone-only{
        height: 28px;
        width: 28px;
        margin-right: 10px;
    }
}

.bookScreen-authorLog-Police {
    @include for-phone-only{
        height: 18px;
        width: 18px;
    }
}


.authorNamePolice {
    @include font-sub-title($font-weight : 600, $color: #252525);
    text-shadow: 0px 2.41333px 6.03333px rgba(0, 0, 0, 0.08),
      0px 0px 24.1333px rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.bookScreen-infoAuthorPanel-container{
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.bookScreen-stock-container {
    border: 1px solid #595959;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 8px;
    padding: 2px 6px;
}

.bookScreen-stock-police {
    @include font-sub-title($font-weight : 500, $font-size: 14px, $line-height: 160%);
    text-transform: uppercase;
}